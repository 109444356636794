import RequestService from './request';
import { ICustomMenuSettings, IMenuSettings } from '../models/dopmSettings';

const GetMenuSettings = async () => {
  const req = new RequestService();
  const result = await req.fetchEndpoint(`settings/menu`);

  return result?.data ?? [];
};

const UpdateMenuSettings = async (menuSettings: IMenuSettings[]) => {
  const req = new RequestService();
  const result = await req.fetchEndpoint(`settings/menu`, 'PUT', {
    menuSettings: menuSettings,
  });

  return result;
};

const CreateCustomMenuSettings = async (menuSetting: ICustomMenuSettings) => {
  const req = new RequestService();
  const result = await req.fetchEndpoint(`settings/customMenu`, 'POST', {
    customMenuSettings: [menuSetting],
  });

  return result;
};

const UpdateCustomMenuSettings = async (
  menuSettings: ICustomMenuSettings[]
) => {
  const req = new RequestService();
  const result = await req.fetchEndpoint(`settings/customMenu`, 'PUT', {
    customMenuSettings: menuSettings,
  });

  return result;
};

const DeleteCustomMenuSettings = async (menuSettingId: number) => {
  const req = new RequestService();
  const result = await req.fetchEndpoint(
    `settings/customMenu/${menuSettingId}`,
    'DELETE'
  );
  return result;
};

export {
  GetMenuSettings,
  UpdateMenuSettings,
  CreateCustomMenuSettings,
  UpdateCustomMenuSettings,
  DeleteCustomMenuSettings,
};
