const PUBLIC_URL: string = 'PUBLIC_URL';
const REACT_APP_API: string = 'REACT_APP_API';
const REACT_APP_PUBLIC_URL: string = 'REACT_APP_PUBLIC_URL';

let isEnvLocalhost = false;

const getEnvUrl = (urlType: string) => {
  isEnvLocalhost = window.location.hostname === 'localhost';
  return urlFactory[urlType]();
};

const getPublicUrl = (api = false) => {
  return (
    window.location.protocol +
    '//' +
    window.location.hostname +
    (isEnvLocalhost && api ? ':3000' : '') +
    '/'
  );
};
const getReactAppApi = () => {
  return getPublicUrl(true) + 'api';
};
const getReactAppPublicUrl = () => {
  return getPublicUrl(true) + (isEnvLocalhost ? '' : 'public-api');
};

const urlFactory = {
  [PUBLIC_URL]: getPublicUrl,
  [REACT_APP_API]: getReactAppApi,
  [REACT_APP_PUBLIC_URL]: getReactAppPublicUrl,
};

export { PUBLIC_URL, REACT_APP_API, REACT_APP_PUBLIC_URL, getEnvUrl };
