type Setting = {
  id: number;
  primaryLogo: string;
  primaryLogoB64?: string;
  secondaryLogo: string;
  secondaryLogoB64?: string;
};

export const defaultSetting: Setting = {
  id: 1,
  primaryLogo: '',
  primaryLogoB64: '',
  secondaryLogo: '',
  secondaryLogoB64: '',
};

export type { Setting };
