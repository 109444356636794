import RequestService from './request';

export const updatePrimaryLogo = async (file: File) => {
  let req = new RequestService();
  const formData = new FormData();
  formData.append('logo', file);
  return await req.fetchEndpoint(
    `setting/primary-logo`,
    'POST',
    formData,
    false
  );
};
// axios interceptors
export const getSetting = async () => {
  const req = new RequestService();
  return await req.fetchEndpoint(`setting`, 'GET', {}, false);
};

export const getPrimaryLogo = async () => {
  const req = new RequestService();
  return await req.fetchEndpoint(`setting/primary-logo`, 'GET', {}, false);
};

//******************************************** */
// Update the secondary logo
export const updateSecondaryLogo = async (file: File) => {
  let req = new RequestService();
  const formData = new FormData();
  formData.append('secondaryLogo', file); // Make sure the key matches your backend
  return await req.fetchEndpoint(
    `setting/secondary-logo`,
    'POST',
    formData,
    false
  );
};

// Get secondary logo
export const getSecondaryLogo = async () => {
  const req = new RequestService();
  return await req.fetchEndpoint(`setting/secondary-logo`, 'GET', {}, false);
};