// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  background-color: #f4f7fc !important;
  overflow-y: auto !important;
}

/*.dropdown-menu.dropdown-menu-right.show {*/
/*  overflow-y: scroll !important;*/
/*  max-height: 200px !important;*/
/*}*/

/*.dropdown-menu {*/
/*  left: -200px;*/
/*}*/

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
svg[data-icon] {
  cursor: pointer;
}

.alert-panel {
  color: white;
  padding: 10px 15px;
  background-color: #dc3545;
  border-color: #f5c6cb;
  position: relative;
  z-index: 10;
}

.info-panel {
  padding: 10px 15px;
  position: relative;
  z-index: 10;
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
`, "",{"version":3,"sources":["webpack://./src/style/index.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;GAYG;;AAEH;EACE,oCAAoC;EACpC,2BAA2B;AAC7B;;AAEA,4CAA4C;AAC5C,mCAAmC;AACnC,kCAAkC;AAClC,IAAI;;AAEJ,mBAAmB;AACnB,kBAAkB;AAClB,IAAI;;AAEJ;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,cAAc;EACd,yBAAyB;EACzB,qBAAqB;AACvB","sourcesContent":["/* body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n} */\n\nbody {\n  background-color: #f4f7fc !important;\n  overflow-y: auto !important;\n}\n\n/*.dropdown-menu.dropdown-menu-right.show {*/\n/*  overflow-y: scroll !important;*/\n/*  max-height: 200px !important;*/\n/*}*/\n\n/*.dropdown-menu {*/\n/*  left: -200px;*/\n/*}*/\n\n.loading {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\nsvg[data-icon] {\n  cursor: pointer;\n}\n\n.alert-panel {\n  color: white;\n  padding: 10px 15px;\n  background-color: #dc3545;\n  border-color: #f5c6cb;\n  position: relative;\n  z-index: 10;\n}\n\n.info-panel {\n  padding: 10px 15px;\n  position: relative;\n  z-index: 10;\n  color: #0c5460;\n  background-color: #d1ecf1;\n  border-color: #bee5eb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
