import React, { PropsWithChildren, useContext } from 'react';
const NavBarContext = React.createContext<{
  setNavBar: (navbar: JSX.Element) => void;
  navBar: JSX.Element;
}>({
  setNavBar: () => {},
  navBar: <></>,
});

export const useNavBar = () => useContext(NavBarContext);

export default function NavBarProvider({ children }: PropsWithChildren<{}>) {
  const [navBar, setNavBar] = React.useState<JSX.Element>(<></>);
  return (
    <NavBarContext.Provider value={{ navBar, setNavBar }}>
      {children}
    </NavBarContext.Provider>
  );
}
