import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { defaultSetting, Setting } from '../../models/setting';
import { getSetting } from '../../services/setting';

type Props = {
  children: ReactNode;
};

type SettingContextType = {
  currentSetting: Setting;
  setCurrentSetting: (setting: Setting) => void;
};

const SettingContextDefaultValue: SettingContextType = {
  currentSetting: defaultSetting,
  setCurrentSetting: () => {},
};

export const SettingContext = createContext<SettingContextType>(
  SettingContextDefaultValue
);

export function useSetting() {
  return useContext(SettingContext);
}

export function SettingProvider({ children }: Props) {
  const [currentSetting, setCurrentSetting] = useState<Setting>(
    SettingContextDefaultValue.currentSetting
  );
  useEffect(() => {
    getSetting().then((res) => {
      if (res?.data) {
        setCurrentSetting(res.data);
      }
    });
  }, []);

  // Provide the context value with the currentSetting state and update function
  const contextValue: SettingContextType = {
    currentSetting,
    setCurrentSetting,
  };

  return (
    <SettingContext.Provider value={contextValue}>
      {children}
    </SettingContext.Provider>
  );
}
