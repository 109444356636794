interface IMenuSettings {
  id: number;
  route: string;
  active: boolean;
  setting_id: number;
}

interface ICustomMenuSettings {
  id: number;
  link: string;
  icon: string;
  active: boolean;
  title: string;
  setting_id: number;
}

export enum RouteName {
  FICHE_SECURITE = 'Fiche Sécurité',
  AUDIT_TERRAIN = 'Audit Terrain',
  FICHE_INFIRMERIE = 'Fiche Infirmerie',
  SUGGESTION = 'Suggestion',
  TOP5 = 'Top 5',
  ASSIGNATION = 'Assignation',
  DOCUMENTATIONS = 'Documentations',
}

export type { IMenuSettings, ICustomMenuSettings };
