// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup > h3 {
  margin-top: 10px;
}

.btn-spinner {
  display: flex;
  align-items: center;
  gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/administration/assignations/dashboard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF","sourcesContent":[".popup {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  & > h3 {\n    margin-top: 10px;\n  }\n}\n.btn-spinner {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
