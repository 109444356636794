type Permission = {
  id: number;
  name: string;
};

export const permissionsList = {
  gestionUtilisateursEtDroits: 1,

  saut_1: '',

  // Dashboard
  lectureDashboard: 6,
  parametrageDashboard: 7,

  saut_2: '',

  // TOP 5
  lectureGraphique: 9,
  ajoutDonneesCategorie: 10,
  // lectureParametrage: 12,
  parametrageTop5: 13,

  saut_3: '',

  //Fiche Securite
  ajoutFicheSecurite: 14,
  lectureFicheSecurite: 15,
  lectureStatistiquesFicheSecurite: 16,
  traitementFicheSecurite: 17,
  parametrageFicheSecurite: 18,

  saut_4: '',

  // Audit Terrain
  realisationAuditTerrain: 19,
  parametrageAuditTerrain: 20,

  saut_5: '',

  // Suggestion
  ajoutSugg: 21,
  lectureSugg: 22,
  lectureStatistiquesSugg: 33,
  traitementSugg: 23,
  parametrageSugg: 24,

  saut_6: '',

  // Assignation
  ajoutAss: 25,
  lectureAss: 26,
  traitementAss: 27,
  parametrageAss: 28,

  saut_7: '',

  // Fiche Infirmerie
  ajoutFicheInf: 29,
  lectureFicheInf: 30,
  lectureStatistiquesInf: 34,
  traitementFicheInf: 31,
  parametrageFicheInf: 32,
};

export type { Permission };
