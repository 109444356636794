import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ICustomMenuSettings, IMenuSettings } from '../../models/dopmSettings';
import { GetMenuSettings } from '../../services/dopmSettings';

type Props = {
  children: ReactNode;
};

type DopmContextType = {
  isSidebarOpen: boolean;
  isSidebarOnRight: boolean;
  toggleSidebar: () => any;
  isMobileDevice: boolean;
  menuRoutes: IMenuSettings[];
  setMenuRoutes: Dispatch<SetStateAction<IMenuSettings[]>>;
  customMenuRoutes: ICustomMenuSettings[];
  setCustomMenuRoutes: Dispatch<SetStateAction<ICustomMenuSettings[]>>;
  refresh: boolean;
  setRefresh: Function;
  dashboardSidebarOpened: boolean;
  setDashboardSidebarOpened: Dispatch<SetStateAction<boolean>>;
};

const DopmContextDefaultValues: DopmContextType = {
  isSidebarOpen: false,
  isSidebarOnRight: false,
  toggleSidebar: () => {},
  isMobileDevice: false,
  menuRoutes: [],
  setMenuRoutes: () => {},
  customMenuRoutes: [],
  setCustomMenuRoutes: () => {},
  refresh: false,
  setRefresh: () => {},
  dashboardSidebarOpened: false,
  setDashboardSidebarOpened: () => {},
};

export const DopmContext = createContext<DopmContextType>(
  DopmContextDefaultValues
);

export function useDopm() {
  return useContext(DopmContext);
}

export function DopmProvider({ children }: Props) {
  const MOBILE_MAX_WIDTH = 600;

  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(
    DopmContextDefaultValues.isSidebarOpen
  );
  const [isSidebarOnRight, setSidebarOnRight] = useState<boolean>(
    DopmContextDefaultValues.isSidebarOnRight
  );
  const [menuRoutes, setMenuRoutes] = useState<IMenuSettings[]>([]);
  const [customMenuRoutes, setCustomMenuRoutes] = useState<
    ICustomMenuSettings[]
  >([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [dashboardSidebarOpened, setDashboardSidebarOpened] =
    useState<boolean>(false);
  let resizeTimeout: any;

  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(
    window.innerWidth <= MOBILE_MAX_WIDTH
  );
  const detectSize = () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {
      setIsMobileDevice(window.innerWidth <= MOBILE_MAX_WIDTH);
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, []);

  useEffect(() => {
    GetMenuSettings().then((settings) => {
      if (settings) {
        const { menuSettings, customMenuSettings } = settings;
        setMenuRoutes(menuSettings);
        setCustomMenuRoutes(customMenuSettings);
      }
    });
  }, [isSidebarOpen, refresh]);

  const value = useMemo(
    () => ({
      isSidebarOpen: isSidebarOpen,
      setSidebarOpen,
      isSidebarOnRight: isSidebarOnRight,
      setSidebarOnRight,
      toggleSidebar,
      isMobileDevice,
      menuRoutes,
      setMenuRoutes,
      customMenuRoutes,
      setCustomMenuRoutes,
      refresh,
      setRefresh,
      dashboardSidebarOpened,
      setDashboardSidebarOpened,
    }),
    [
      isSidebarOpen,
      isSidebarOnRight,
      isMobileDevice,
      menuRoutes,
      customMenuRoutes,
      refresh,
      dashboardSidebarOpened,
    ]
  );

  function toggleSidebar() {
    setSidebarOpen(!isSidebarOpen);
  }

  return <DopmContext.Provider value={value}>{children}</DopmContext.Provider>;
}
